//公共产品管理模块的接口
import request from '@/service/request'

export function getMerchantList(data) {
  return request({
    url: '/externaladmin/authService/user/distributorUserList',
    method: 'post',
    data
  })
}

//选择分销商
export function selectMerchant(data) {
  return request({
    url: '/externaladmin/productService/finishedProd/distribute',
    method: 'post',
    data
  })
}

//创建公共产品风格
export function createStyle(data) {
  return request({
    url: '/externaladmin/productService/finishedProdTag/create',
    method: 'post',
    data
  })
}

//编辑公共产品风格
export function editStyle(data) {
  return request({
    url: '/externaladmin/productService/finishedProdTag/update',
    method: 'post',
    data
  })
}

//获取公共产品风格列表
export function getStyleList(data) {
  return request({
    url: '/externaladmin/productService/finishedProdTag/list',
    method: 'post',
    data
  })
}

//获取公共产品列表
export function getProductList(data) {
  return request({
    url: '/externaladmin/productService/finishedProd/productList',
    method: 'post',
    data
  })
}

//批量关联公共产品风格
export function batchRelationStyle(data) {
  return request({
    url: '/externaladmin/productService/finishedProdTagAssociation/bulkCreate',
    method: 'post',
    data
  })
}

//批量更新刀版图
export function batchUpdateKnife(data) {
  return request({
    url: '/externaladmin/productService/customSpecificProduct/updateKnifeLayoutPath',
    method: 'post',
    data
  })
}

//公共产品上架
export function putOnProducts(data) {
  return request({
    url: '/externaladmin/productService/finishedProd/putOnShelf',
    method: 'post',
    data
  })
}

//公共产品下架
export function putOffProducts(data) {
  return request({
    url: '/externaladmin/productService/finishedProd/offShelf',
    method: 'post',
    data
  })
}

//获取外部sku列表
export function getSkuList(data) {
  return request({
    url: '/externaladmin/productService/customSpecificProductSkuMapping/list',
    method: 'post',
    data
  })
}

// 产品标记侵权
export function sign(data) {
  return request({
    url: '/externaladmin/productService/finishedProd/tortUpdate',
    method: 'post',
    data
  })
}


// 图片标记侵权
export function pictureSign(data) {
  return request({
    url: '/externaladmin/productService/image/tortUpdate',
    method: 'post',
    data
  })
}

