<template>
  <el-dialog
    v-el-drag-dialog
    title="选择分销商"
    :visible.sync="dialogVisible"
    top="5vh"
    width="888px"
    custom-class="assign-distributor-dialog dialog-header-border"
    append-to-body
  >
    <el-row>
      <el-card
        v-for="item in cardList"
        :key="item.prop"
        shadow="never"
        class="el-col-12"
        v-loading="loading[item.prop]"
      >
        <template #header>
          <el-row>
            <el-col :span="8" style="line-height: 40px">
              {{ item.label }}
            </el-col>
            <el-col :span="16">
              <el-input v-model="searchObj[item.prop]" prefix-icon="el-icon-search" class="header-search" clearable>
              </el-input>
            </el-col>
          </el-row>
        </template>
        <el-checkbox-group v-model="checkedList[item.prop]">
          <el-checkbox v-for="dItem in filterData(item.prop)" :key="dItem.value" :label="dItem.value">{{
            dItem.label
          }}</el-checkbox>
        </el-checkbox-group>
      </el-card>
    </el-row>
    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <loadingBtn type="primary" @click="onsubmit">确 定</loadingBtn>
    </template>
  </el-dialog>
</template>

<script>
import { getAllData, getOpenList } from '@/views/product/maintain/basicsMange/module/api'

import { dialogComponentsMixin } from '@/mixins'
import { PIC_ENABLE, REQUEST_ALL_DATA, SHELVES } from '@/utils/constant'
import { getMerchantList } from '@/api/finishedProductManageApi'
import { distributorImageDataSet, distributorPrototypeDataSet } from '@/api/auth/distributorAssign'
import { validatenull } from '@/components/avue/utils/validate'
import { deepClone } from '@/components/avue/utils/util'

let list = {
  prototype: {
    cardList: [
      {
        label: '待分配的原型',
        prop: 'prototype',
        api: getAllData,
        mergeData: {
          status: SHELVES
        },
        props: {
          label: 'name',
          value: 'id'
        }
      },
      {
        label: '待分配的分销商',
        prop: 'distributor',
        api: getMerchantList,
        props: {
          label: 'nickName',
          value: 'id'
        }
      }
    ],
    assignApi({ prototype, distributor }) {
      return distributorPrototypeDataSet({
        prototypeIdList: prototype,
        mainAccountUidList: distributor
      })
    }
  },
  picture: {
    cardList: [
      {
        label: '待分配的图片',
        prop: 'picture',
        api: getOpenList,
        mergeData: {
          disable: PIC_ENABLE
        },
        props: {
          label: 'title',
          value: 'id'
        }
      },
      {
        label: '待分配的分销商',
        prop: 'distributor',
        api: getMerchantList,
        props: {
          label: 'nickName',
          value: 'id'
        }
      }
    ],
    assignApi({ picture, distributor }) {
      return distributorImageDataSet({
        imageIdList: picture,
        mainAccountUidList: distributor
      })
    }
  }
}

export default {
  mixins: [dialogComponentsMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'prototype'
    }
  },
  data() {
    return {
      curType: this.type,
      loading: {},
      data: {},
      checkedList: {},
      newData: {},
      searchObj: {}
    }
  },
  computed: {
    curItem() {
      return list[this.curType]
    },
    cardList() {
      return this.curItem.cardList.map((item) => {
        let { prop, api, mergeData, props } = item
        item.getList = async () => {
          let { data } = this
          if (data[prop]?.length) return data[prop]
          let detail = await awaitResolveDetail(
            api({
              ...mergeData,
              ...REQUEST_ALL_DATA
            })
          )
          if (!detail) return []
          return detail.map((item) => {
            return {
              label: item[props.label],
              value: item[props.value]
            }
          })
        }
        return item
      })
    }
  },
  watch: {
    cardList: {
      handler(n) {
        let { loading, data, checkedList } = this
        n.forEach((item) => {
          this.$set(loading, item.prop, loading[item.prop] || false)
          this.$set(data, item.prop, data[item.prop] || [])
          this.$set(checkedList, item.prop, checkedList[item.prop] || [])
        })
      },
      immediate: true,
      deep: true
    },
    dialogVisible() {
      let { data } = this
      for (const dataKey in data) {
        data[dataKey] = []
      }
    }
  },
  methods: {
    async onsubmit() {
      let valid = await this.validate()
      if (!valid) return

      let res = await awaitResolve(this.curItem.assignApi(this.checkedList))
      // console.log(res)
      if (!res) return
      this.$message.success('操作成功')
      this.dialogVisible = false
    },
    validate() {
      let { checkedList, cardList } = this
      return cardList.every((item) => {
        let valid = !validatenull(checkedList[item.prop])
        if (!valid) {
          this.$message.warning(`${item.label} 请至少选择一条数据`)
        }
        return valid
      })
    },

    dialogOpen(value) {
      this.dialogVisible = true
      let { data, loading, checkedList, curType } = this
      this.cardList.forEach(async (item) => {
        checkedList[item.prop] = []
        loading[item.prop] = true
        data[item.prop] = await item.getList()
        loading[item.prop] = false

        if (item.prop === curType) {
          let values = data[item.prop].map(({ value }) => value)
          checkedList[curType] = value.filter((item) => values.includes(item))
        }
      })
    },
    filterData(prop) {
      let {
        data: { [prop]: data },
        searchObj: { [prop]: searchVal }
      } = this
      let reg = new RegExp(searchVal, 'mg')
      return data.filter((item) => {
        reg.lastIndex = 0
        return reg.test(item.label)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .assign-distributor-dialog {
  .el-card__body {
    height: 500px;
    overflow: auto;
  }
  .el-checkbox-group {
    margin-bottom: -10px;
  }
  .el-checkbox {
    margin-bottom: 10px;
  }
}
</style>
