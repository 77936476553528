import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//获得子账号
export function childList(data = {}) {
  return request({
    url: '/externaladmin/authService/user/childrenList',
    method: 'post',
    data
  })
}
// 分配数据接口
export function assignData(data = {}) {
  return request({
    url: '/externaladmin/productService/productPrototype/distribution',
    method: 'post',
    data
  })
}
// 获取数据接口
export function getAllData(data = {}) {
  return request({
    url: '/externaladmin/prototypeService/productPrototype/list',
    method: 'post',
    data
  })
}

// 获取数据接口
export function getOpenList(data = {}) {
  return request({
    url: '/externaladmin/productService/image/openList',
    method: 'post',
    data
  })
}

// 获取图片接口
export function getImageList(data = {}) {
  return request({
    url: '/externaladmin/productService/image/list',
    method: 'post',
    data
  })
}

// 获取分销商接口
export function getDistributionList(data = {}, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/distributorUserList',
    method: 'post',
    data
  })
}
